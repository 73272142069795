// Experiences data.

const experiences = [
	{
		id: 1,
		title: 'Associate Software Developer',
		category: 'IBM',
        date: 'Since August 2024',
		img: require('@/assets/images/IBM.png'),
	},
	{
		id: 2,
		title: 'Software Developer Intern',
		category: 'DRW',
        date: 'May 2023 - August 2023',
		img: require('@/assets/images/drw.png'),
	},
	{
		id: 3,
		title: 'Jr. Software Engineer Intern',
		category: 'Tucows',
        date: 'May 2022 - April 2023',
		img: require('@/assets/images/Tucows.png'),
	},
	{
		id: 4,
		title: 'Technical Systems Analyst',
		category: 'Royal Bank of Canada (RBC)',
        date: 'May 2021 - August 2021',
		img: require('@/assets/images/RBC.png'),
	},
	{
		id: 5,
		title: 'Drain Coordinator',
		category: 'The Drain (McMaster Engineering Society Merchandise Store)',
        date: 'July 2023 - April 2024',
		img: require('@/assets/images/drain.png'),
	},
	{
		id: 6,
		title: 'Teaching Assistant',
		category: 'McMaster University (Department of Computing and Software)',
        date: 'January 2022 - April 2022',
		img: require('@/assets/images/mac.png'),
	},
	{
		id: 7,
		title: 'VP Sponsorship',
		category: 'First Year Integration Conference (FYIC)',
        date: 'June 2022 - November 2022',
		img: require('@/assets/images/fyic_logo.png'),
	},
	{
		id: 8,
		title: 'Mentorship Coordinator',
		category: 'McMaster Engineering Society (MES)',
        date: 'September 2022 - April 2022',
		img: require('@/assets/images/mes.png'),
	}
];

export default experiences;
