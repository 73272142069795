<script>
export default {
	props: ['project'],
};
</script>

<template>
	<!-- <router-link
		to="/projects/single-project"
		class="rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
		aria-label="Single Project"
	> -->
	<div class="rounded-xl shadow-lg hover:shadow-xl mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark">
		<div>
			<img
				:src="project.img"
				:alt="project.title"
				class="rounded-t-xl border-none"
			/>
		</div>
		<div class="text-center px-4 py-6">
			<p
				class="font-general-semibold text-xl text-ternary-dark dark:text-ternary-light font-semibold mb-2"
			>
				{{ project.title }}
			</p>
			<span
				class="font-general-semibold text-lg text-ternary-dark dark:text-ternary-light"
				>{{ project.category }}</span
			>
			<p
				class="font-general-medium text-lg text-ternary-dark dark:text-ternary-light"
				>{{ project.date }}</p
			>
		</div>
	</div>
	<!-- </router-link> -->
</template>

<style lang="scss" scoped></style>
